<template>
  <div class="flex-container non-scrollable">
    <div v-if="companySettings" class="scrollable">
      <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid p-5">
        <span class="page-sub-title">{{ $t('admin.params.commercial.simulationTitle') }}</span>
        <div class="flex flex-column justify-content-between mt-3">
          <div class="flex flex-column mb-3">
            <label for="commercialSources" :class="{'p-error':v$.commercialSources.$invalid && submitted}" class="w-full block text-900 underline font-medium mb-3"> {{ $t('admin.params.commercial.sourceLabel') }}</label>
            <Chips id="commercialSources" v-model="commercialSources" :class="{'p-invalid':v$.commercialSources.$invalid && submitted}" class="w-5">
              <template #chip="slotProps">
                <div>
                  <span>{{ slotProps.value }}</span>
                </div>
              </template>
            </Chips>
            <div v-if="v$.commercialSources.$error && submitted" class="mb-3">
            <span id="commercialSources-error" v-for="(error, index) of v$.commercialSources.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
            </div>
          </div>

<!--          <div class="flex flex-column mb-3">-->
<!--            <label for="commercialManagementRate" :class="{'p-error':v$.commercialManagementRate.$invalid && submitted}" class="w-full block text-900 underline font-medium mb-3"> {{ $t('admin.params.commercial.managementRateLabel') }}</label>-->
<!--            <Chips id="commercialManagementRate" v-model="commercialManagementRate" :class="{'p-invalid':v$.commercialManagementRate.$invalid && submitted}" class="w-5" @add="onManagementRateAdded">-->
<!--              <template #chip="slotProps">-->
<!--                <div>-->
<!--                  <span>{{ slotProps.value }} %</span>-->
<!--                </div>-->
<!--              </template>-->
<!--            </Chips>-->
<!--            <div v-if="v$.commercialManagementRate.$error && submitted" class="mb-3">-->
<!--        <span id="commercialManagementRate-error" v-for="(error, index) of v$.commercialManagementRate.$errors" :key="index">-->
<!--          <InlineMessage>{{error.$message}}</InlineMessage>-->
<!--        </span>-->
<!--            </div>-->
<!--            <div class="flex flex-row align-items-center mt-2 mb-2">-->
<!--              <Checkbox id="commercialManagementRoof" v-model="commercialManagementRoof" :binary="true" class="mr-2" />-->
<!--              <label for="commercialManagementRoof">{{ $t('admin.params.commercial.managementRateRoofLabel') }}</label>-->
<!--            </div>-->
<!--          </div>-->

           <div class="flex flex-column mb-3">
            <label class="w-3 block text-900 underline font-medium mb-3"> {{ $t('admin.params.commercial.transportLabel') }}</label>
            <div class="flex flex-row align-items-center mt-2 mb-2">
              <Checkbox id="commercialTransportsCar" v-model="commercialTransportsCar" class="mr-2" :binary="true"/>
              <label for="commercialTransportsCar">{{ $t('admin.params.commercial.transportCarLabel') }}</label>
            </div>
            <div class="flex flex-row align-items-center mt-2 mb-2">
              <Checkbox id="commercialTransportsPublic" v-model="commercialTransportsPublic" class="mr-2" :binary="true" />
              <label for="commercialTransportsPublic">{{ $t('admin.params.commercial.transportPublicLabel') }}</label>
            </div>
            <div class="flex flex-row align-items-center mt-2 mb-2">
              <Checkbox id="commercialTransportsMotorbike" v-model="commercialTransportsMotorbike" class="mr-2" :binary="true"/>
              <label for="commercialTransportsMotorbike">{{ $t('admin.params.commercial.transportMotorBikeLabel') }}</label>
            </div>
            <div class="flex flex-row align-items-center mt-2 mb-2">
              <Checkbox id="commercialTransportsMotorcycles" v-model="commercialTransportsMotorcycles" class="mr-2" :binary="true"/>
              <label for="commercialTransportsMotorcycles">{{ $t('admin.params.commercial.transportMotorcycleLabel') }}</label>
            </div>
          </div>

          <financial-reserve
              v-if="loaded"
              :commercial-financial-reserve="commercialFinancialReserve"
              show-subtitle
              @onChange="disableSavingButton"
              @onBlur="commercialFinancialReserveUpdated"
          />

          <div class="flex flex-column mb-3">
            <label class="w-full block text-900 underline font-medium mb-3"> {{ $t('admin.params.commercial.contractTypeLabel') }}</label>
            <div class="flex flex-row align-items-center mt-2 mb-2">
              <Checkbox id="commercialContractCdd" v-model="commercialContractCdd" class="mr-2" :binary="true" />
              <label for="commercialContractCdd">{{ $t('admin.params.commercial.contractTypeCddLabel') }}</label>
            </div>
            <div class="flex flex-row align-items-center mt-2 mb-4">
              <Checkbox id="commercialContractCdi" :binary="true" v-model="commercialContractCdi" class="mr-2"/>
              <label for="commercialContractCdi">{{ $t('admin.params.commercial.contractTypeCdiLabel') }}</label>
            </div>
          </div>

          <div class="flex flex-column mb-3">
            <label class="w-full block text-900 underline font-medium mb-3"> {{ $t('admin.params.commercial.prospectSimulationTemplateLabel') }}</label>
          </div>

          <div class="mb-4">
            <table class="table-simulation-prospect">
              <tr>
                <td class="checkbox"></td>
                <td class="outer-top-cell content-simulation-prospect font-bold">Nombre de jours facturés</td>
              </tr>
              <tr>
                <td class="checkbox"></td>
                <td class="content-simulation-prospect pl-4">* Facturation par jour</td>
              </tr>
              <tr>
                <td class="checkbox"></td>
                <td class="outer-bottom-cell content-simulation-prospect font-bold">= CA HT facturé</td>
              </tr>
              <tr class="line-splitter">
                <td class="cell-splitter"></td>
              </tr>
              <tr>
                <td class="checkbox"></td>
                <td class="colored-cell outer-top-cell content-simulation-prospect font-bold pl-4">- Frais de gestion (FG) à 8%</td>
              </tr>
              <tr>
                <td class="checkbox"></td>
                <td class="colored-cell outer-bottom-cell content-simulation-prospect font-bold">= CA HT après Frais de Gestion</td>
              </tr>
              <tr class="line-splitter">
                <td class="cell-splitter"></td>
              </tr>
              <tr>
                <td class="checkbox"><Checkbox v-model="commercialProspectSimulationInvoiceLines.first" :binary="true" /></td>
                <td class="outer-top-cell content-simulation-prospect font-bold pl-3">- Coût global du bulletin de salaire sur 18 jours</td>
              </tr>
              <tr>
                <td class="checkbox"><Checkbox v-model="commercialProspectSimulationInvoiceLines.second" :binary="true" /></td>
                <td class="content-simulation-prospect pl-7">* Charges patronales estimées</td>
              </tr>
              <tr>
                <td class="checkbox"><Checkbox v-model="commercialProspectSimulationInvoiceLines.third" :binary="true" /></td>
                <td class="content-simulation-prospect pl-7">* Cotisations salariales estimées</td>
              </tr>
              <tr>
                <td class="checkbox"><Checkbox v-model="commercialProspectSimulationInvoiceLines.fourth" :binary="true" /></td>
                <td class="content-simulation-prospect pl-7">* Salaire Net estimé</td>
              </tr>
              <tr>
                <td class="checkbox"><Checkbox v-model="commercialProspectSimulationInvoiceLines.fifth" :binary="true" /></td>
                <td class="content-simulation-prospect pl-7">* Prélèvement à la source (PAS) 0 %</td>
              </tr>
              <tr>
                <td class="checkbox"><Checkbox v-model="commercialProspectSimulationInvoiceLines.sixth" :binary="true" /></td>
                <td class="content-simulation-prospect font-bold pl-3">- Frais professionnels remboursables sur justificatifs ou forfaits</td>
              </tr>
              <tr>
                <td class="checkbox"><Checkbox v-model="commercialProspectSimulationInvoiceLines.seventh" :binary="true" /></td>
                <td class="content-simulation-prospect font-bold pl-3">- Ticket restaurant sur 20 jours</td>
              </tr>
              <tr>
                <td class="checkbox"><Checkbox v-model="commercialProspectSimulationInvoiceLines.eighth" :binary="true" /></td>
                <td class="outer-bottom-cell content-simulation-prospect font-bold pl-3">- Cagnotte constituée (restant de CA HT)</td>
              </tr>
              <tr class="line-splitter">
                <td class="cell-splitter"></td>
              </tr>
              <tr>
                <td class="checkbox"></td>
                <td class="colored-cell outer-top-cell content-simulation-prospect font-bold">Net après PAS / + frais professionnels</td>
              </tr>
              <tr>
                <td class="checkbox"><Checkbox v-model="commercialProspectSimulationInvoiceLines.ninth" :binary="true" /></td>
                <td class="colored-cell content-simulation-prospect font-bold pl-5">Ratio NET A PAYER après PAS / 18J de CA après FG</td>
              </tr>
              <!--            DUPLICATE -->
              <!--            <tr>-->
              <!--              <td class="checkbox"><Checkbox v-model="commercialProspectSimulationInvoiceLines.tenth" :binary="true" /></td>-->
              <!--              <td class="colored-cell outer-bottom-cell content-simulation-prospect font-bold pl-5">Ratio NET A PAYER après PAS / 18J de CA après FG</td>-->
              <!--            </tr>-->

              <!--  todo: Change due to refacto of simualation  -->
<!--              <tr class="line-splitter text">-->
<!--                <td class="cell-splitter"><Checkbox v-model="commercialProspectSimulationInvoiceLines.tenth" :binary="true" @input="resetSumUp" /></td>-->
<!--                <td class="cell-splitter font-bold underline">En résumé</td>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td class="checkbox"><Checkbox v-model="commercialProspectSimulationInvoiceLines.eleventh" :binary="true" /></td>-->
<!--                <td class="outer-top-cell content-simulation-prospect font-bold">Montant de la facturation 20 jours</td>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td class="checkbox"><Checkbox v-model="commercialProspectSimulationInvoiceLines.twelfth" :binary="true" /></td>-->
<!--                <td class="content-simulation-prospect font-bold">- Frais de gestion (FG) à 8%</td>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td class="checkbox"><Checkbox v-model="commercialProspectSimulationInvoiceLines.thirteenth" :binary="true" /></td>-->
<!--                <td class="content-simulation-prospect font-bold">- Coût global du bulletin de salaire sur 18 jours</td>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td class="checkbox"><Checkbox v-model="commercialProspectSimulationInvoiceLines.fourteenth" :binary="true" /></td>-->
<!--                <td class="content-simulation-prospect font-bold">- Frais professionnels rembousables sur justificatifs ou forfaits</td>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td class="checkbox"><Checkbox v-model="commercialProspectSimulationInvoiceLines.fifteenth" :binary="true" /></td>-->
<!--                <td class="content-simulation-prospect font-bold">- Cagnotte constituée (restant de CA HT)</td>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td class="checkbox"><Checkbox v-model="commercialProspectSimulationInvoiceLines.sixteenth" :binary="true" /></td>-->
<!--                <td class="outer-bottom-cell content-simulation-prospect font-bold line-height-3">Ratio NET A PAYER / CA HT avec réintégration de 2 jour(s) supp si utilisés avec les dispositifs sociaux</td>-->
<!--              </tr>-->
            </table>
          </div>

          <span class="page-sub-title">Email Simulation</span>
          <div class="mt-3 mb-4 w-8">
            <Editor v-model="simulationEmailTemplate" editorStyle="height: 320px;"/>
          </div>


          <span class="page-sub-title pt-5">{{ $t('admin.params.commercial.commercialTitle') }}</span>

          <div class="flex flex-column mb-2 mt-3">
            <table class="w-6">
              <thead>
              <tr>
                <th class="w-8"></th>
                <th class="w-1">Oui</th>
                <th class="w-1">Non</th>
                <th class="w-1"></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="py-2">
            <span class="font-bold w-4 mr-4">Souhaitez-vous utiliser le modèle des
              <a class="font-medium underline clickable-link text-primary" v-tooltip.top="'Voir le modèle des conditions particulères'" @click="openParticularConditionsPreview">
                conditions particulières du contrat de prestation
              </a>
              de Kaizen ?
            </span>
                </td>
                <!--                  todo: bug -> not the opened modal but the radio button itself-->
                <!--                  <div @click="openWorkContractParticularConditionsAcceptationPreview">tadatatata</div>-->
                <td class="text-center">
                  <RadioButton v-model="commercialTemplateContractParticularsConditions" :value="true" @change="openWorkContractParticularConditionsAcceptationPreview"/>
                </td>
                <td class="text-center">
                  <RadioButton v-model="commercialTemplateContractParticularsConditions" :value="false" @click="saveContract"/>
                </td>
                <td></td>
              </tr>
              <tr>
                <td class="py-2">
            <span class="font-bold w-4 mr-4">
              Souhaitez-vous utiliser le modèle des
              <a class="font-medium underline clickable-link text-primary" v-tooltip.top="'Voir le modèle des conditions générales'" @click="openGeneralConditionsPreview">
                conditions générales de prestation
              </a>
              de Kaizen ? <span class="font-medium">(Si non, importez vos propres conditions générales)</span>
            </span>
                </td>
                <td class="text-center">
                  <RadioButton v-model="commercialTemplateContractGeneralConditions" :value="true" @change="openWorkContractGeneralConditionAcceptationPreview"/>
                </td>
                <td class="text-center">
                  <RadioButton v-model="commercialTemplateContractGeneralConditions" :value="false" @change="saveContract"/>
                </td>
                <td>
                  <Button
                    v-if="!commercialTemplateContractGeneralConditions"
                    :icon="(commercialOwnGeneralConditionsTemplateId === '' || !commercialOwnGeneralConditionsTemplateId) ? 'pi pi-upload' : 'pi pi-eye'"
                    class="p-button-rounded p-button-text"
                    @click="openOwnGeneralCondition"
                  />
                </td>
              </tr>
              </tbody>
            </table>

            <div class="flex flex-row justify-center align-items-center mb-2 mt-3">
              <label for="commercialTermsAndConditionsClientParticipation" :class="{'p-error':v$.commercialTermsAndConditionsClientParticipation.$invalid && submitted}" class="w-2 block text-900 font-medium"> {{ $t('admin.params.commercial.termsAndConditionsClientParticipationLabel') }}</label>
              <InputText id="commercialTermsAndConditionsClientParticipation" v-model="commercialTermsAndConditionsClientParticipation" :class="{'p-invalid':v$.commercialTermsAndConditionsClientParticipation.$invalid && submitted}" type="text" class="w-3" />
              <div v-if="v$.commercialTermsAndConditionsClientParticipation.$error && submitted" class="mb-3">
            <span id="commercialTermsAndConditionsClientParticipation-error" v-for="(error, index) of v$.commercialTermsAndConditionsClientParticipation.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
              </div>
            </div>
            <div class="flex flex-row justify-center align-items-center mb-2">
              <label for="commercialPaymentMethod" :class="{'p-error':v$.commercialPaymentMethod.$invalid && submitted}" class="w-2 block text-900 font-medium"> {{ $t('admin.params.commercial.paymentMethodLabel') }}</label>
              <InputText id="commercialPaymentMethod" v-model="commercialPaymentMethod" :class="{'p-invalid':v$.commercialPaymentMethod.$invalid && submitted}" type="text" class="w-3" />
              <div v-if="v$.commercialPaymentMethod.$error && submitted" class="mb-3">
            <span id="commercialPaymentMethod-error" v-for="(error, index) of v$.commercialPaymentMethod.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
              </div>
            </div>
            <div class="flex flex-row justify-center align-items-center mb-2">
              <label for="commercialExceptionalExpenses" :class="{'p-error':v$.commercialExceptionalExpenses.$invalid && submitted}" class="w-2 block text-900 font-medium"> {{ $t('admin.params.commercial.exceptionalExpensesLabel') }}</label>
              <InputText id="commercialExceptionalExpenses" v-model="commercialExceptionalExpenses" :class="{'p-invalid':v$.commercialExceptionalExpenses.$invalid && submitted}" type="text" class="w-3" />
              <div v-if="v$.commercialExceptionalExpenses.$error && submitted" class="mb-3">
            <span id="commercialExceptionalExpenses-error" v-for="(error, index) of v$.commercialExceptionalExpenses.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
              </div>
            </div>
            <div class="flex flex-row justify-center align-items-center mb-2">
              <label for="commercialRcpInsurance" :class="{'p-error':v$.commercialRcpInsurance.$invalid && submitted}" class="w-2 block text-900 font-medium"> {{ $t('admin.params.commercial.rcpInsuranceLabel') }}</label>
              <InputText id="commercialRcpInsurance" v-model="commercialRcpInsurance" :class="{'p-invalid':v$.commercialRcpInsurance.$invalid && submitted}" type="text" class="w-3" />
              <div v-if="v$.commercialRcpInsurance.$error && submitted" class="mb-3">
            <span id="commercialRcpInsurance-error" v-for="(error, index) of v$.commercialRcpInsurance.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
              </div>
            </div>
            <div class="flex flex-row justify-center align-items-center mb-2">
              <label for="commercialFinancialGuarantee" :class="{'p-error':v$.commercialFinancialGuarantee.$invalid && submitted}" class="w-2 block text-900 font-medium"> {{ $t('admin.params.commercial.financialGuaranteeLabel') }}</label>
              <InputText id="commercialFinancialGuarantee" v-model="commercialFinancialGuarantee" :class="{'p-invalid':v$.commercialFinancialGuarantee.$invalid && submitted}" type="text" class="w-3" />
              <div v-if="v$.commercialFinancialGuarantee.$error && submitted" class="mb-3">
            <span id="commercialFinancialGuarantee-error" v-for="(error, index) of v$.commercialTermcommercialFinancialGuarantee.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
              </div>
            </div>
            <div class="flex flex-row justify-center align-items-center mb-2">
              <label for="commercialCourt" :class="{'p-error':v$.commercialCourt.$invalid && submitted}" class="w-2 block text-900 font-medium"> {{ $t('admin.params.commercial.commercialCourtLabel') }}</label>
              <InputText id="commercialCourt" v-model="commercialCourt" :class="{'p-invalid':v$.commercialCourt.$invalid && submitted}" type="text" class="w-3" />
              <div v-if="v$.commercialCourt.$error && submitted" class="mb-3">
            <span id="commercialCourt-error" v-for="(error, index) of v$.commercialCourt.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
              </div>
            </div>
          </div>

          <div v-if="hasAccess($route.name, true)" class="float-button">
            <Button :label="$t('admin.params.company.registerButton')" type="submit" class="w-full p-3" :disabled="disableSaving"></Button>
          </div>
        </div>
      </form>
    </div>
    <dialog-file-preview
        :dialog-title="$t('admin.params.commercial.dialogParticularsConditionsContractTemplate')"
        :is-active="dialogParticularConditions"
        :file-src="particularConditionsSrc"
        :modal="dialogParticularConditionsModal"
        :closable="dialogParticularConditionsClosable"
        :close-button-label="dialogParticularConditionsCloseButtonLabel"
        :validate-button-label="dialogParticularConditionsAcceptButtonLabel"
        :close-button="dialogParticularConditionsCloseButton"
        :validate-button="dialogParticularConditionsAcceptButton"
        :file-mime-type="'application/pdf'"
        :auto-load-file="false"
        @validateFile="dialogParticularConditionsFileValidation"
        @closePreview="dialogParticularConditionsCloseFilePreview"
        @closeDialog="dialogParticularConditions = false"
    />
    <dialog-file-preview
        :dialog-title="$t('admin.params.rh.dialogGeneralConditionsWorkContractTemplate')"
        :is-active="dialogGeneralConditions"
        :file-src="generalConditionsSrc"
        :modal="dialogGeneralConditionsModal"
        :closable="dialogGeneralConditionsClosable"
        :close-button-label="dialogGeneralConditionsCloseButtonLabel"
        :validate-button-label="dialogGeneralConditionsAcceptButtonLabel"
        :close-button="dialogGeneralConditionsCloseButton"
        :validate-button="dialogGeneralConditionsAcceptButton"
        :file-mime-type="'application/pdf'"
        :auto-load-file="false"
        @validateFile="dialogGeneralConditionsFileValidation"
        @closePreview="dialogGeneralConditionsCloseFilePreview"
        @closeDialog="dialogGeneralConditions = false"
    />
    <dialog-file-preview
        :dialog-title="$t('admin.params.commercial.dialogOwnGeneralsConditionsContractTemplate')"
        :is-active="dialogOwnGeneralConditions"
        :file-src="ownGeneralConditionsSrc"
        :file-mime-type="'application/pdf'"
        :accept-file-type="'application/pdf'"
        :auto-load-file="false"
        :auto-upload-file="false"
        replace-file-button
        delete-button
        @replaceFile="updateOwnGeneralConditions"
        @firstFileUpload="updateOwnGeneralConditions"
        @deleteFile="deleteOwnGeneralConditions"
        @closeDialog="dialogOwnGeneralConditions = false"
    />
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { helpers, minLength, required} from "@vuelidate/validators";
import Alert from "@/utils/Alert";
import { mapState } from "vuex";
import {
  disablePComponentsOfCurrentPage,
  formatHtmlToPdf,
  getBase64,
  getBase64FromDownloadedFile,
  hasAccess
} from "@/utils/Misc";
import DialogFilePreview from "@/components/DialogFilePreview";
import FinancialReserve from "@/components/FinancialReserve";

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: "Commercial",
  components: { FinancialReserve, DialogFilePreview },
  data () {
    return {
      hasAccess: hasAccess,
      commercialSources: [],

      commercialManagementRate: [],
      commercialManagementRoof: false,

      commercialTransportsCar: false,
      commercialTransportsPublic: false,
      commercialTransportsMotorbike: false,
      commercialTransportsMotorcycles: false,

      commercialFinancialReserve: {
        commercialFinancialReserveRateJuniorValue: 0,
        commercialFinancialReserveRateJuniorBase: {},

        commercialFinancialReserveRateSeniorValue: 0,
        commercialFinancialReserveRateSeniorBase: {},

        commercialFinancialReserveRateFixedPriceValue: 0,
        commercialFinancialReserveRateFixedPriceBase: {},

        commercialFinancialReserveRateOthersValue: 0,
        commercialFinancialReserveRateOthersBase: {}
      },

      simulationEmailTemplate: '',

      commercialContractCdd: false,
      commercialContractCdi: false,

      commercialTermsAndConditionsClientParticipation: '',
      commercialPaymentMethod: '',
      commercialExceptionalExpenses: '',
      commercialRcpInsurance: '',
      commercialFinancialGuarantee: '',
      commercialCourt: '',

      // check on how we can simplify this by not naming each line as a cardinal number,
      commercialProspectSimulationInvoiceLines: {},
      commercialTemplateContractParticularsConditions: false,
      commercialTemplateContractGeneralConditions: false,
      commercialTemplateContractOwnGeneralConditions: false,
      // save the id but show the file as input
      commercialOwnGeneralConditionsTemplateId: '',
      commercialUploadedDocument: null,

      dialogParticularConditions: false,
      dialogParticularConditionsModal: false,
      dialogParticularConditionsClosable: true,
      dialogParticularConditionsCloseButtonLabel: '',
      dialogParticularConditionsAcceptButtonLabel: '',
      dialogParticularConditionsCloseButton: false,
      dialogParticularConditionsAcceptButton: false,
      dialogParticularConditionsFileValidation: () => {},
      dialogParticularConditionsCloseFilePreview: () => {},

      dialogGeneralConditions: false,
      dialogGeneralConditionsModal: false,
      dialogGeneralConditionsClosable: true,
      dialogGeneralConditionsCloseButtonLabel: '',
      dialogGeneralConditionsAcceptButtonLabel: '',
      dialogGeneralConditionsCloseButton: false,
      dialogGeneralConditionsAcceptButton: false,
      dialogGeneralConditionsFileValidation: () => {},
      dialogGeneralConditionsCloseFilePreview: () => {},

      dialogOwnGeneralConditions: false,
      dialogOwnGeneralConditionsReplaceButtonLabel: '',
      dialogOwnGeneralConditionsReplaceButton: false,
      dialogOwnGeneralConditionsReplaceFile: () => {},

      particularConditionsSrc: null,
      generalConditionsSrc: null,
      ownGeneralConditionsSrc: null,

      loaded: false,
      disableSaving: false,
      submitted: false,
      accept: null,
    }
  },
  async created () {
    // get only company settings ?
    await this.$store.dispatch('misc/getAllSettings', {})
    await this.initializeFields()
    this.loaded = true
  },
  watch: {
    loaded () {
      if (this.loaded) {
        this.$nextTick(() => {
          if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
        })
      }
    }
  },
  computed: {
    ...mapState({
      settings: state => state.misc.settings.Commercial,
      companySettings: state => state.misc.settings.Entreprise,
      constants: state => state.constants
    })
  },
  validations() {
    return {
      // todo: later on describe the validation precisely, if so don't forget "*" after each require fields
      commercialSources: {
        required: helpers.withMessage(this.$t('admin.params.commercial.requiredField'), required),
        minLength: minLength(1)
      },
      // commercialManagementRate: {
      //   required: helpers.withMessage(this.$t('admin.params.commercial.requiredField'), required),
      //   minLength: minLength(1)
      // },
      commercialTermsAndConditionsClientParticipation: {
        required: helpers.withMessage(this.$t('admin.params.commercial.requiredField'), required),
      },
      commercialPaymentMethod: {
        required: helpers.withMessage(this.$t('admin.params.commercial.requiredField'), required),
      },
      commercialExceptionalExpenses: {
        required: helpers.withMessage(this.$t('admin.params.commercial.requiredField'), required),
      },
      commercialRcpInsurance: {
        required: helpers.withMessage(this.$t('admin.params.commercial.requiredField'), required),
      },
      commercialFinancialGuarantee: {
        required: helpers.withMessage(this.$t('admin.params.commercial.requiredField'), required),
      },
      commercialCourt: {
        required: helpers.withMessage(this.$t('admin.params.commercial.requiredField'), required),
      }
    }
  },
  methods: {
    async initializeFields () {
      this.commercialOwnGeneralConditionsTemplateId = this.settings.contratCommercial.conditionsGeneralesCustomId
      this.commercialSources = this.settings.sources
      // this.commercialManagementRate = this.settings.tarifGestion.values
      this.commercialManagementRoof = this.settings.tarifGestion.plafond

      this.commercialTransportsCar = this.settings.transport.voiture
      this.commercialTransportsPublic = this.settings.transport.transports
      this.commercialTransportsMotorbike = this.settings.transport.moto
      this.commercialTransportsMotorcycles = this.settings.transport.cyclomoteur

      this.commercialFinancialReserve.commercialFinancialReserveRateJuniorValue = this.settings.tauxReserveFinanciere.junior.value * 100
      this.commercialFinancialReserve.commercialFinancialReserveRateJuniorBase = this.settings.tauxReserveFinanciere.junior.base

      this.commercialFinancialReserve.commercialFinancialReserveRateSeniorValue = this.settings.tauxReserveFinanciere.senior.value * 100
      this.commercialFinancialReserve.commercialFinancialReserveRateSeniorBase = this.settings.tauxReserveFinanciere.senior.base

      this.commercialFinancialReserve.commercialFinancialReserveRateFixedPriceValue = this.settings.tauxReserveFinanciere?.forfait?.value * 100
      this.commercialFinancialReserve.commercialFinancialReserveRateFixedPriceBase = this.settings.tauxReserveFinanciere?.forfait?.base

      this.commercialFinancialReserve.commercialFinancialReserveRateOthersValue = this.settings.financialReserveRateComplement.value * 100
      this.commercialFinancialReserve.commercialFinancialReserveRateOthersBase = this.settings.financialReserveRateComplement.base

      this.commercialContractCdd = this.settings.contrat.cdd
      this.commercialContractCdi = this.settings.contrat.cdi

      this.simulationEmailTemplate = this.settings.simulationEmailTemplate

      this.commercialTermsAndConditionsClientParticipation = this.settings.contrat.modalitesParticipationClient
      this.commercialPaymentMethod = this.settings.contrat.moyenPaiement
      this.commercialExceptionalExpenses = this.settings.contrat.fraisExceptionnels
      this.commercialRcpInsurance = this.settings.contrat.assuranceRcp
      this.commercialFinancialGuarantee = this.settings.contrat.garantieFinanciere
      this.commercialCourt = this.settings.contrat.tribunalCommerce

      this.commercialProspectSimulationInvoiceLines = this.settings.simulationProspect
      this.commercialTemplateContractParticularsConditions = this.settings.contratCommercial.conditionsParticulieresKaizen
      this.commercialTemplateContractGeneralConditions = this.settings.contratCommercial.conditionsGeneralesKaizen
      // this.commercialTemplateContractOwnGeneralConditions = this.settings.contratCommercial.conditionsGeneralesCustom

    },
    async handleSubmit (isFormValid) {
      this.submitted = true

      // todo: Must choose at least one automatic scale by transport used
      // todo: Can un-choose the default particularConditions template -- in this case they manage this on their own

      if (!this.commercialContractCdd && !this.commercialContractCdi) {
        Alert.errorMessage(this, 'adminCommercialFormUnSelectedContract')
        return
      }

      // if ((this.commercialTemplateContractOwnGeneralConditions && this.commercialUploadedDocument === null)) {
      //   Alert.errorMessage(this, 'adminCommercialFormUnUploadedFile')
      //   return
      // }
      if (this.companySettings.convention === this.constants.COLLECTIVE_CONVENTION.PORTAGE &&
          (!(this.commercialFinancialReserve.commercialFinancialReserveRateJuniorValue >= 0) || !this.commercialFinancialReserve.commercialFinancialReserveRateJuniorBase) &&
          (!(this.commercialFinancialReserve.commercialFinancialReserveRateSeniorValue >= 0) || !this.commercialFinancialReserve.commercialFinancialReserveRateSeniorBase) &&
          (!(this.commercialFinancialReserve.commercialFinancialReserveRateFixedPriceValue >= 0) || !this.commercialFinancialReserve.commercialFinancialReserveRateFixedPriceBase)
      ) {
        Alert.errorMessage(this, 'adminCommercialFormTauxReserveFinanciereUnset')
        return
      }

      if (!(this.commercialFinancialReserve.commercialFinancialReserveRateOthersValue >= 0) || !this.commercialFinancialReserve.commercialFinancialReserveRateOthersBase) {
        Alert.errorMessage(this, 'adminCommercialFormTauxReserveFinanciereUnset')
        return
      }

      if (!isFormValid || (this.commercialTemplateContractOwnGeneralConditions && this.commercialUploadedDocument === null)) {
        Alert.errorMessage(this, 'fieldRequired', {symbole: '(*)'})
        return
      }
      await this.saveSettings()
    },
    preparePayload () {
      return {
        Commercial: {
          contrat: {
            cdd: this.commercialContractCdd,
                cdi: this.commercialContractCdi,
                modalitesParticipationClient: this.commercialTermsAndConditionsClientParticipation,
                moyenPaiement: this.commercialPaymentMethod,
                fraisExceptionnels: this.commercialExceptionalExpenses,
                assuranceRcp: this.commercialRcpInsurance,
                garantieFinanciere: this.commercialFinancialGuarantee,
                tribunalCommerce: this.commercialCourt
          },
          contratCommercial: {
            // conditionsGeneralesCustom: this.commercialTemplateContractOwnGeneralConditions,
            conditionsGeneralesCustomId: this.commercialOwnGeneralConditionsTemplateId,
                conditionsGeneralesKaizen: this.commercialTemplateContractGeneralConditions,
                conditionsParticulieresKaizen: this.commercialTemplateContractParticularsConditions
          },
          simulationProspect: this.commercialProspectSimulationInvoiceLines,
          simulationEmailTemplate: formatHtmlToPdf(this.simulationEmailTemplate),
          sources: this.commercialSources,
          tarifGestion: {
            plafond: this.commercialManagementRoof,
            // values: this.commercialManagementRate
          },
          tauxReserveFinanciere: {
            junior: {
              value: this.commercialFinancialReserve.commercialFinancialReserveRateJuniorValue / 100,
              base: this.commercialFinancialReserve.commercialFinancialReserveRateJuniorBase
            },
            senior: {
              value: this.commercialFinancialReserve.commercialFinancialReserveRateSeniorValue / 100,
              base: this.commercialFinancialReserve.commercialFinancialReserveRateSeniorBase,
            },
            forfait: {
              value: this.commercialFinancialReserve.commercialFinancialReserveRateFixedPriceValue / 100,
              base: this.commercialFinancialReserve.commercialFinancialReserveRateFixedPriceBase,
            },
          },
          financialReserveRateComplement: {
            value: (this.commercialFinancialReserve.commercialFinancialReserveRateOthersValue / 100),
            base: this.commercialFinancialReserve.commercialFinancialReserveRateOthersBase
          },
          transport: {
            moto: this.commercialTransportsMotorbike,
                transports: this.commercialTransportsPublic,
                voiture: this.commercialTransportsCar,
                cyclomoteur: this.commercialTransportsMotorcycles
          },
        }
      }
    },
    getContractPayload () {
      return {
        contratCommercial: {
          // conditionsGeneralesCustom: this.commercialTemplateContractOwnGeneralConditions,
          conditionsGeneralesCustomId: this.commercialOwnGeneralConditionsTemplateId,
          conditionsGeneralesKaizen: this.commercialTemplateContractGeneralConditions,
          conditionsParticulieresKaizen: this.commercialTemplateContractParticularsConditions
        }
      }
    },
    async saveSettings () {
      try {
        const payload = this.preparePayload()
        await this.$store.dispatch('misc/updateSettings', payload)
        this.$toast.add({severity: 'success', summary: 'Succès', detail: 'Paramètres sauvegardés', life: 3000});
      } catch (e) {
        this.$toast.add({severity: 'error', summary: 'Erreur', detail: 'Une erreur s\'est produite', life: 3000});
      }
    },
    async saveSpecificSettings (payload) {
      try {
        await this.$store.dispatch('misc/updateCommercialSettings', payload)
        this.$toast.add({severity: 'success', summary: 'Succès', detail: 'Paramètres sauvegardés', life: 3000});
      } catch (e) {
        this.$toast.add({severity: 'error', summary: 'Erreur', detail: 'Une erreur s\'est produite', life: 3000});
      }
    },
    async downloadExistingFile(file, callback) {
      file = await this.$store.dispatch('file/downloadFile', {id: file})
      getBase64FromDownloadedFile(file, (result) => {
        callback(result)
      })
    },
    async downloadOwnGeneralCondition(callback) {
      if (this.commercialOwnGeneralConditionsTemplateId.toString().length > 0 && !this.commercialTemplateContractGeneralConditions) {
        await this.downloadExistingFile(this.commercialOwnGeneralConditionsTemplateId, (result) => {
          this.ownGeneralConditionsSrc = result
          callback()
        })
      }
    },
    async openOwnGeneralCondition () {
      // open a new one
      if (this.commercialOwnGeneralConditionsTemplateId === '' || !this.commercialOwnGeneralConditionsTemplateId) {
        this.ownGeneralConditionsSrc = null
        this.dialogOwnGeneralConditions = true
        return
      }

      // get the actual one and put it as a source
      const result = await this.$store.dispatch('file/downloadFile', { id: this.commercialOwnGeneralConditionsTemplateId })
      getBase64FromDownloadedFile(result, (result) => {
        this.ownGeneralConditionsSrc = result
        this.dialogOwnGeneralConditions = true
      })
    },
    // todo: this logic should be in the back
    async updateOwnGeneralConditions (payload) {
      if (this.commercialOwnGeneralConditionsTemplateId?.length > 0) {
        await this.$store.dispatch('file/deleteFile', { id: this.commercialOwnGeneralConditionsTemplateId })
        this.commercialOwnGeneralConditionsTemplateId = ''
      }
      const newGeneralConditions = await this.$store.dispatch('file/uploadFile', payload.form)
      this.commercialOwnGeneralConditionsTemplateId = newGeneralConditions.id
      await this.saveSpecificSettings(this.getContractPayload())
      this.resetOwnGeneralConditionDialog()
    },
    async deleteOwnGeneralConditions () {
      await this.$store.dispatch('file/deleteFile', { id: this.commercialOwnGeneralConditionsTemplateId })
      this.commercialOwnGeneralConditionsTemplateId = ''
      await this.saveSpecificSettings(this.getContractPayload())
      this.resetOwnGeneralConditionDialog()
    },
    resetOwnGeneralConditionDialog () {
      this.ownGeneralConditionsSrc = null
      this.dialogOwnGeneralConditions = false
    },
    async downloadGeneralConditionsTemplate(callback) {
      const generalConditionsTemplateBuffer = await this.$store.dispatch('mission/generateGeneralConditionsContract')
      getBase64(generalConditionsTemplateBuffer, (result) => {
        this.generalConditionsSrc = result
        callback()
      })
    },
    downloadAndOpenGeneralConditionsTemplate () {
      this.downloadGeneralConditionsTemplate(() => {
        this.dialogGeneralConditions = true
      })
    },
    openGeneralConditionsPreview () {
      this.dialogGeneralConditionsModal = false
      this.dialogGeneralConditionsClosable = true
      this.dialogGeneralConditionsCloseButtonLabel = ''
      this.dialogGeneralConditionsAcceptButtonLabel = ''
      this.dialogGeneralConditionsCloseButton = false
      this.dialogGeneralConditionsAcceptButton = false
      this.dialogParticularConditionsFileValidation = () => {}
      this.dialogParticularConditionsCloseFilePreview = () => {}
      this.downloadAndOpenGeneralConditionsTemplate()
    },
    openWorkContractGeneralConditionAcceptationPreview () {
      this.dialogGeneralConditionsModal = true
      this.dialogGeneralConditionsClosable = false
      this.dialogGeneralConditionsCloseButtonLabel = 'Refuser'
      this.dialogGeneralConditionsAcceptButtonLabel = 'Accepter'
      this.dialogGeneralConditionsCloseButton = true
      this.dialogGeneralConditionsAcceptButton = true
      this.dialogGeneralConditionsFileValidation = async () => {
        await this.saveSpecificSettings(this.getContractPayload())
        this.dialogGeneralConditions = false
      }
      this.dialogGeneralConditionsCloseFilePreview = async () => {
        this.commercialTemplateContractGeneralConditions = false
        await this.saveSpecificSettings(this.getContractPayload())
        this.dialogGeneralConditions = false
      }
      this.downloadAndOpenGeneralConditionsTemplate()
    },
    async downloadParticularConditionsTemplate(callback) {
      const particularConditionsTemplateBuffer = await this.$store.dispatch('mission/generateParticularConditionsContract')
      getBase64(particularConditionsTemplateBuffer, (result) => {
        this.particularConditionsSrc = result
        callback()
      })
    },
    downloadAndOpenParticularConditionsTemplate () {
      this.downloadParticularConditionsTemplate(() => {
        this.dialogParticularConditions = true
      })
    },
    openParticularConditionsPreview () {
      this.dialogParticularConditionsModal = false
      this.dialogParticularConditionsClosable = true
      this.dialogParticularConditionsCloseButtonLabel = ''
      this.dialogParticularConditionsAcceptButtonLabel = ''
      this.dialogParticularConditionsCloseButton = false
      this.dialogParticularConditionsAcceptButton = false
      this.dialogParticularConditionsFileValidation = () => {}
      this.dialogParticularConditionsCloseFilePreview = () => {}
      this.downloadAndOpenParticularConditionsTemplate()
    },
    openWorkContractParticularConditionsAcceptationPreview () {
      this.dialogParticularConditionsModal = true
      this.dialogParticularConditionsClosable = false
      this.dialogParticularConditionsCloseButtonLabel = 'Refuser'
      this.dialogParticularConditionsAcceptButtonLabel = 'Accepter'
      this.dialogParticularConditionsCloseButton = true
      this.dialogParticularConditionsAcceptButton = true
      this.dialogParticularConditionsFileValidation = async () => {
        await this.saveSpecificSettings(this.getContractPayload())
        this.dialogParticularConditions = false
      }
      this.dialogParticularConditionsCloseFilePreview = async () => {
        this.commercialTemplateContractParticularsConditions = false
        await this.saveSpecificSettings(this.getContractPayload())
        this.dialogParticularConditions = false
      }
      this.downloadAndOpenParticularConditionsTemplate()
    },
    async saveContract () {
      await this.saveSpecificSettings(this.getContractPayload())
    },
    onManagementRateAdded (chips) {
      const lastInsertedChipIndex = chips.value.length - 1
      // enable values that are typed with a comma instead of a dot
      const chipValue = chips.value[lastInsertedChipIndex].replace(',', '.')
      if (chipValue.split('.').length - 1 > 1) {
        this.commercialManagementRate.splice(lastInsertedChipIndex, 1)
        this.$toast.add({severity:'error', summary:'Erreur', detail:'Le format est invalide', life: 3000});
        return
      }
      const chipValidity = isNaN(parseFloat(chipValue))
      if (chipValidity) {
        // call error below the input via a specific error with vuelidate
        this.commercialManagementRate.splice(lastInsertedChipIndex, 1)
        this.$toast.add({severity:'error', summary:'Erreur', detail:'Le format est invalide', life: 3000});
      }
    },
    resetSumUp (value) {
      this.commercialProspectSimulationInvoiceLines.eleventh = value
      this.commercialProspectSimulationInvoiceLines.twelfth = value
      this.commercialProspectSimulationInvoiceLines.thirteenth = value
      this.commercialProspectSimulationInvoiceLines.fourteenth = value
      this.commercialProspectSimulationInvoiceLines.fifteenth = value
      this.commercialProspectSimulationInvoiceLines.sixteenth = value
    },
    resetGeneralConditions (value) {
      if (!value) {
        this.commercialTemplateContractOwnGeneralConditions = false
        this.commercialTemplateContractGeneralConditions = false
      } else {
        this.commercialTemplateContractGeneralConditions = true
      }
    },
    disableSavingButton () {
      this.disableSaving = true
    },
    enableSavingButton () {
      this.disableSaving = false
    },
    commercialFinancialReserveUpdated (payload) {
      this.commercialFinancialReserve.commercialFinancialReserveRateJuniorValue = payload.commercialFinancialReserveRateJuniorValue
      this.commercialFinancialReserve.commercialFinancialReserveRateJuniorBase = payload.commercialFinancialReserveRateJuniorBase

      this.commercialFinancialReserve.commercialFinancialReserveRateSeniorValue = payload.commercialFinancialReserveRateSeniorValue
      this.commercialFinancialReserve.commercialFinancialReserveRateSeniorBase = payload.commercialFinancialReserveRateSeniorBase

      this.commercialFinancialReserve.commercialFinancialReserveRateFixedPriceValue = payload.commercialFinancialReserveRateFixedPriceValue
      this.commercialFinancialReserve.commercialFinancialReserveRateFixedPriceBase = payload.commercialFinancialReserveRateFixedPriceBase

      this.commercialFinancialReserve.commercialFinancialReserveRateOthersValue = payload.commercialFinancialReserveRateOthersValue
      this.commercialFinancialReserve.commercialFinancialReserveRateOthersBase = payload.commercialFinancialReserveRateOthersBase

      this.enableSavingButton()

      // baseOptions = JSON.parse(JSON.stringify(this.constants.baseOptions)).filter((baseOption) => {
      //   return this.constants.defaultBaseOptions.findIndex((defaultBaseOption) => defaultBaseOption === baseOption.type) !== -1;
      // })
    }
  }
}
</script>

<style scoped>
.table-simulation-prospect {
  border-collapse: collapse;
}

.checkbox {
  border: 0 solid;
  padding: 2px 20px 2px 2px;
}

.content-simulation-prospect {
  padding: 3px;
  border: 2px solid black;
  border-right: 3px solid black;
  border-left: 3px solid black;
}

.outer-top-cell {
  border-top: 3px solid black;
}

.outer-bottom-cell {
  border-bottom: 3px solid black;
}

.line-splitter {
  height: 10px;
}

.line-splitter.text{
  height: 30px;
}

.colored-cell {
  background-color: rgb(151, 194, 96);
}
</style>

<style>
 .centered-header .p-column-header-content {
   justify-content: center;
 }
 .checkboxFooter {
   text-align: center !important;
 }
</style>
