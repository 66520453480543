<template>
  <div class="flex flex-column mb-3">
    <label v-if="showSubtitle" class="w-full block text-900 underline font-medium mb-4"> {{ $t('admin.params.commercial.financialReserveRateLabel') }}</label>
    <div v-if="settings.Entreprise.convention === constants.COLLECTIVE_CONVENTION.PORTAGE" class="flex flex-row align-items-center mb-3">
      <label for="commercialFinancialReserveRateJunior" class="w-2 block text-900 font-medium"> {{ $t('admin.params.commercial.financialReserveRateJuniorLabel') }}</label>
      <InputNumber
        id="commercialFinancialReserveRateJunior"
        v-model="commercialFinancialReserveRateJuniorValue"
        class="w-3 mr-2"
        mode="decimal"
        :min="0"
        :minFractionDigits="2"
        :maxFractionDigits="2"
        suffix=" %" highlightOnFocus
        @focus="onChange"
        @input="(event) => commercialFinancialReserveRateJuniorValue = event.value"
        @blur="onBlur"
      />
      <span class="mr-2">du</span>
      <Dropdown v-model="commercialFinancialReserveRateJuniorBase" :options="getBaseOptions" option-label="label" @focus="onChange" @blur="onBlur"/>
    </div>
    <div v-if="settings.Entreprise.convention === constants.COLLECTIVE_CONVENTION.PORTAGE" class="flex flex-row align-items-center mb-3">
      <label for="commercialFinancialReserveRateSenior" class="w-2 block text-900 font-medium"> {{ $t('admin.params.commercial.financialReserveRateSeniorLabel') }}</label>
      <InputNumber
        id="commercialFinancialReserveRateSenior"
        v-model="commercialFinancialReserveRateSeniorValue"
        class="w-3 mr-2"
        mode="decimal"
        :min="0"
        :minFractionDigits="2"
        :maxFractionDigits="2"
        suffix=" %" highlightOnFocus
        @focus="onChange"
        @input="(event) => commercialFinancialReserveRateSeniorValue = event.value"
        @blur="onBlur"
      />
      <span class="mr-2">du</span>
      <Dropdown v-model="commercialFinancialReserveRateSeniorBase" :options="getBaseOptions" option-label="label" @focus="onChange" @blur="onBlur"/>
    </div>
    <div v-if="settings.Entreprise.convention === constants.COLLECTIVE_CONVENTION.PORTAGE" class="flex flex-row align-items-center mb-3">
      <label for="commercialFinancialReserveRateFixedPrice" class="w-2 block text-900 font-medium"> {{ $t('admin.params.commercial.financialReserveRateFixedPriceLabel') }}</label>
      <InputNumber
        id="commercialFinancialReserveRateFixedPrice"
        v-model="commercialFinancialReserveRateFixedPriceValue"
        class="w-3 mr-2"
        mode="decimal"
        :min="0"
        :minFractionDigits="2"
        :maxFractionDigits="2"
        suffix=" %" highlightOnFocus
        @focus="onChange"
        @input="(event) => commercialFinancialReserveRateFixedPriceValue = event.value"
        @blur="onBlur"
      />
      <span class="mr-2">du</span>
      <Dropdown v-model="commercialFinancialReserveRateFixedPriceBase" :options="getBaseOptions" option-label="label" @focus="onChange" @blur="onBlur"/>
    </div>
    <div class="flex flex-row align-items-center">
      <label for="commercialFinancialReserveRateOthers" class="w-2 block text-900 font-medium">
        {{ $t(settings.Entreprise.convention === constants.COLLECTIVE_CONVENTION.PORTAGE ? 'admin.params.commercial.financialReserveRateOthersLabel' : 'admin.params.commercial.financialReserveSyntecRateOthersLabel') }}
      </label>
      <InputNumber
        id="commercialFinancialReserveRateOthers"
        v-model="commercialFinancialReserveRateOthersValue"
        class="w-3 mr-2"
        mode="decimal"
        :min="0"
        :minFractionDigits="2"
        :maxFractionDigits="2"
        suffix=" %" highlightOnFocus
        @focus="onChange"
        @input="(event) => commercialFinancialReserveRateOthersValue = event.value"
        @blur="onBlur"
      />
      <span class="mr-2">du</span>
      <Dropdown v-model="commercialFinancialReserveRateOthersBase" :options="getBaseOptions" option-label="label" @focus="onChange" @blur="onBlur"/>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "FinancialReserve",
  emits: {
    onChange: null,
    onBlur: null,
  },
  props: {
    commercialFinancialReserve: {
      type: Object,
      default: () => {
        return {
          commercialFinancialReserveRateJuniorValue: 0,
          commercialFinancialReserveRateJuniorBase: {},
          commercialFinancialReserveRateSeniorValue: 0,
          commercialFinancialReserveRateSeniorBase: {},
          commercialFinancialReserveRateFixedPriceValue: 0,
          commercialFinancialReserveRateFixedPriceBase: {},
          commercialFinancialReserveRateOthersValue: 0,
          commercialFinancialReserveRateOthersBase: {}
        }
      },
      required: true
    },
    showSubtitle: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    commercialFinancialReserve (value) {
      this.initCommercialFinancialReserve(JSON.parse(JSON.stringify(value)))
    }
  },
  data () {
    return {
      commercialFinancialReserveRateJuniorValue: 0,
      commercialFinancialReserveRateJuniorBase: {},

      commercialFinancialReserveRateSeniorValue: 0,
      commercialFinancialReserveRateSeniorBase: {},

      commercialFinancialReserveRateFixedPriceValue: 0,
      commercialFinancialReserveRateFixedPriceBase: {},

      commercialFinancialReserveRateOthersValue: 0,
      commercialFinancialReserveRateOthersBase: {},
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
      settings: state => state.misc.settings
    }),
    getBaseOptions () {
      const specialBaseOptions = this.settings.Entreprise.convention === this.constants.COLLECTIVE_CONVENTION.PORTAGE ?
        [ this.constants.BASE_TYPES.MINIMUM_CONVENTIONAL_SALARY ]
        : [ this.constants.BASE_TYPES.BRUT_SALARY ]
      const defaultBaseOptions = [
        this.constants.BASE_TYPES.PMSS,
        this.constants.BASE_TYPES.CAF,
        this.constants.BASE_TYPES.CAP,
        this.constants.BASE_TYPES.CA_AFTER_TGF,
        this.constants.BASE_TYPES.CA_AFTER_TGP,
        ...specialBaseOptions
      ]
      return JSON.parse(JSON.stringify(this.constants.data.baseOptions)).filter((baseOption) => {
        return defaultBaseOptions.findIndex((defaultBaseOption) => defaultBaseOption === baseOption.type) !== -1;
      })
    }
  },
  created () {
    this.initCommercialFinancialReserve()
  },
  methods: {
    initCommercialFinancialReserve (updatedObject = null) {
      const propsCommercialFinancialReserve = JSON.parse(JSON.stringify(this.commercialFinancialReserve))

      this.commercialFinancialReserveRateJuniorValue = updatedObject?.commercialFinancialReserveRateJuniorValue ?? (propsCommercialFinancialReserve.commercialFinancialReserveRateJuniorValue ?? 0)
      this.commercialFinancialReserveRateJuniorBase = updatedObject?.commercialFinancialReserveRateJuniorBase ?? (propsCommercialFinancialReserve.commercialFinancialReserveRateJuniorBase ?? (this.settings.Entreprise.convention === this.constants.COLLECTIVE_CONVENTION.PORTAGE ? this.getBaseOptions[0] : null))

      this.commercialFinancialReserveRateSeniorValue = updatedObject?.commercialFinancialReserveRateSeniorValue ?? (propsCommercialFinancialReserve.commercialFinancialReserveRateSeniorValue ?? 0)
      this.commercialFinancialReserveRateSeniorBase = updatedObject?.commercialFinancialReserveRateSeniorBase ?? (propsCommercialFinancialReserve.commercialFinancialReserveRateSeniorBase ?? (this.settings.Entreprise.convention === this.constants.COLLECTIVE_CONVENTION.PORTAGE ? this.getBaseOptions[0] : null))

      this.commercialFinancialReserveRateFixedPriceValue = updatedObject?.commercialFinancialReserveRateFixedPriceValue ?? (propsCommercialFinancialReserve.commercialFinancialReserveRateFixedPriceValue ?? 0)
      this.commercialFinancialReserveRateFixedPriceBase = updatedObject?.commercialFinancialReserveRateFixedPriceBase ?? (propsCommercialFinancialReserve.commercialFinancialReserveRateFixedPriceBase ?? (this.settings.Entreprise.convention === this.constants.COLLECTIVE_CONVENTION.PORTAGE ? this.getBaseOptions[0] : null))

      this.commercialFinancialReserveRateOthersValue = updatedObject?.commercialFinancialReserveRateOthers ?? (propsCommercialFinancialReserve.commercialFinancialReserveRateOthersValue ?? 0)
      this.commercialFinancialReserveRateOthersBase = updatedObject?.commercialFinancialReserveRateOthersBase ?? (propsCommercialFinancialReserve.commercialFinancialReserveRateOthersBase ?? this.getBaseOptions[0])

      // in case the default options are set, need to update the parent for the save
      this.onBlur()
    },
    onChange () {
      this.$emit('onChange')
    },
    onBlur () {
      process.nextTick(() => {
        this.$emit('onBlur', {
          commercialFinancialReserveRateJuniorValue: this.commercialFinancialReserveRateJuniorValue,
          commercialFinancialReserveRateJuniorBase: this.commercialFinancialReserveRateJuniorBase,

          commercialFinancialReserveRateSeniorValue: this.commercialFinancialReserveRateSeniorValue,
          commercialFinancialReserveRateSeniorBase: this.commercialFinancialReserveRateSeniorBase,

          commercialFinancialReserveRateFixedPriceValue: this.commercialFinancialReserveRateFixedPriceValue,
          commercialFinancialReserveRateFixedPriceBase: this.commercialFinancialReserveRateFixedPriceBase,

          commercialFinancialReserveRateOthersValue: this.commercialFinancialReserveRateOthersValue,
          commercialFinancialReserveRateOthersBase: this.commercialFinancialReserveRateOthersBase
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
