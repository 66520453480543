<template>
  <!--  todo: ajouter le téléchargement par un bouton (pour les images) -->
  <Dialog
    v-model:visible="dialogActive"
    class="dialog-file-preview"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '50vw'}"
    :modal="modal"
    :closable="closable"
    :draggable="!modal"
    @hide="closeDialog"
  >
    <template #header>
      <h3 class="mb-2">{{ dialogTitle }}</h3>
    </template>
    <div class="flex flex-column justify-content-center align-items-center" :style="`height:${iframeHeight}; max-height:1000px`">
      <Dropdown v-if="defineFileType && !localFileSrc" v-model="fileType" :options="fileTypeOptions"
                class="mb-3 w-4" filter placeholder="Séléctionnez un type de document"/>
      <FileUpload
          id="fileUploaderButton"
          ref="fileUploaderButton"
          v-if="firstUploadButton && !localFileSrc"
          mode="basic"
          :accept="acceptFileType"
          uploadIcon="pi pi-upload"
          :choose-label="firstUploadButtonLabel"
          :showUploadButton="false"
          :maxFileSize="10000000"
          :disabled="defineFileType && !fileType"
          class="p-button-outlined"
          auto
          custom-upload
          @uploader="(file) => onUpload(file,'firstFileUpload')"
      />
      <div v-else-if="localFileSrc !== null" class="flex align-items-center justify-content-center p-0 col-12">
        <iframe v-if="localMimeType === 'application/pdf'" :src="localFileSrc" :style="`height:${iframeHeight}; max-height:1000px`" width="100%"/>
        <img v-else :src="localFileSrc" :style="`height:${iframeHeight}; max-height:300px`" class="cursor-pointer mr-4"/>
      </div>
      <div v-else-if="!firstUploadButton">
        Le document auquel vous essayez d'accéder n'existe pas, ou n'a pas encore été importé
      </div>
    </div>

    <template #footer>
      <div class="flex flex-row-reverse">
        <!--  Todo: only before any actions, might add if there is a form to send ?  -->
        <Button
          v-if="validateButton || (fileUploaded && localFileForm !== null && localFileSrc !== null)"
          :label="validateButtonLabel"
          class="mr-2"
          @click="prepareConfirmDialogForValidate"
        />
        <!--  Todo: only before an upload and closeButton  -->
        <Button
          v-if="closeButton && !fileUploaded"
          :label="closeButtonLabel"
          class="p-button-outlined mr-2"
          @click="$emit('closePreview')"
        />
        <!--  Todo: only after an upload and cancelButton, force cancelButton  -->
        <Button
          v-if="fileUploaded && localFileForm !== null && localFileSrc !== null"
          :label="cancelButtonLabel"
          class="p-button-outlined mr-2"
          @click="cancelFileUploaded"
        />

        <!--        todo: might miss the fact that we -->
        <FileUpload
          v-if="hasAccess(this.$route.name, true) && replaceFileButton && !fileUploaded"
          mode="basic"
          class="mr-2"
          :accept="acceptFileType"
          :choose-label="replaceFileButtonLabel"
          :showUploadButton="false"
          :maxFileSize="10000000"
          :disabled="!localFileSrc"
          auto
          custom-upload
          @uploader="(file) => onUpload(file, 'replaceFile')"
        />
        <Button
          v-if="deleteButton && localFileSrc !== null && !fileUploaded"
          :label="deleteButtonLabel"
          class="mr-2 p-button-danger"
          @click="prepareConfirmDialogForDelete"
        />
      </div>
    </template>
  </Dialog>
  <dialog-confirm
      :is-active="confirmDialog"
      :refuse-action="closeConfirmDialog"
      :validate-action="confirmDialogValidate"
      :title="confirmDialogTitle"
      :text-info="confirmDialogInfo"
      :text-question="confirmDialogQuestion"
  />
</template>

<script>
import {getBase64, getBase64FromDownloadedFile, hasAccess} from "@/utils/Misc";
import DialogConfirm from "@/components/DialogConfirm.vue";
import {mapState} from "vuex";

export default {
  name: "DialogFilePreview",
  components: { DialogConfirm },
  emits: ['closeDialog', 'replaceFile', 'firstFileUpload', 'validateFile', 'deleteFile', 'closePreview', 'cancelFileUpload'],
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
    /**
     * By default, autoload the fileId set via the default 'file/downloadFile' route
     */
    autoLoadFile: {
      type: Boolean,
      default: true,
    },
    /**
     * Required by default to load automatically the file you want to preview
     */
    fileId: {
      type: String,
      required: false
    },
    /**
     * By default, do the default logic with Document in case of all the props requirements passed
     */
    autoUploadFile: {
      type: Boolean,
      default: true,
    },
    /**
     * Must be set if the file is created and the 'replace' and 'delete' actions are ON
     */
    documentId: {
      type: String,
      required: false
    },
    /**
     * Must be set if the file is a document and can be created
     */
    documentType: {
      type: String,
      required: false
    },
    /**
     * Must be set if the file is a document and can be created
     */
    independentId: {
      type: String,
      required: false
    },
    /**
     * Must be use it if the fileSrc origin you want to change is not on the default 'file/downloadFile' route
     */
    fileSrc: {
      default: null,
      required: false
    },
    /**
     * Must be use it with the fileSrc to properly define what mimetype the file is
     */
    // required only when you pass a file src
    fileMimeType: {
      type: String,
      default: '',
      required: false
    },
    updateKey: {
      type: Number,
      default: 0
    },
    dialogTitle: {
      type: String,
      default: '',
      required: true
    },
    validateButton: {
      type: Boolean,
      default: false,
    },
    validateButtonLabel: {
      type: String,
      default: 'Valider',
    },
    closeButton: {
      type: Boolean,
      default: false,
    },
    closeButtonLabel: {
      type: String,
      default: 'Fermer',
    },
    deleteButton: {
      type: Boolean,
      default: false,
    },
    deleteButtonLabel: {
      type: String,
      default: 'Supprimer le document',
    },
    cancelButtonLabel: {
      type: String,
      default: 'Annuler',
    },
    replaceFileButton: {
      type: Boolean,
      default: false,
    },
    replaceFileButtonLabel: {
      type: String,
      default: 'Remplacer',
    },
    modal: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    firstUploadButton: {
      type: Boolean,
      default: true,
    },
    firstUploadButtonLabel: {
      type: String,
      default: 'Uploader un contrat',
    },
    acceptFileType: {
      type: String,
      default: 'image/*,application/pdf',
    },
    /**
     * Trigger a validation dialog on each action buttons (validate, replace, delete)
     */
    validationDialog: {
      type: Boolean,
      default: false,
    },
    defineFileType: {
      type: Boolean,
      default: false,
    },
    fileTypeOptions: {
      type: Array,
      default: () => [],
    },
    // autoUpload: {
    //   type: Boolean,
    //   default: false,
    // }
  },
  watch: {
    async isActive (value) {
      await this.init()
      this.dialogActive = value
      process.nextTick(() => {
        this.calculateIframeHeight()
        // todo: try to auto click under certain conditions to gain one click
        // if (this.dialogActive && this.autoUpload && !this.defineFileType && this.firstUploadButton && !this.localFileSrc) {
        //   // const uploadButton = document.getElementById('fileUploaderButton')
        //   // console.log(uploadButton)
        //   // uploadButton.click()
        //   console.log(this.$refs.fileUploaderButton)
        //   this.$refs.fileUploaderButton.click()
        // }
      })
    }
  },
  data () {
    return {
      hasAccess: hasAccess,
      dialogActive: false,
      iframeHeight: '',

      fileUploaded: false,
      localFileSrc: null,
      localPreviousSrc: null,
      localPreviousMimeType: null,
      localFileForm: null,
      localMimeType: null,
      fileType: null,

      uploadedFileEvent: null,

      confirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogInfo: '',
      confirmDialogQuestion: 'Souhaitez-vous effectuer cette action ?',
      confirmDialogValidate: () => {}
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants
    })
  },
  async created () {
    await window.addEventListener('resize', this.calculateIframeHeight)
    await this.init()
    this.dialogActive = this.isActive
  },
  unmounted () {
    window.removeEventListener('resize', this.calculateIframeHeight)
  },
  methods: {
    async init () {
      if (!this.autoLoadFile) {
        this.loadSource(null, null, true)
      } else if (this.fileId && this.autoLoadFile) {
        await this.loadFile()
      } else if (!this.fileId && this.autoLoadFile) {
        // console.error('ERROR AUTO-LOAD: id du fichier manquant')
      }
      this.calculateIframeHeight()
    },
    loadSource (file, source, fromProps = false) {
      if (fromProps) {
        this.localFileSrc = this.fileSrc
        this.localPreviousSrc = this.fileSrc
        this.localPreviousMimeType = this.fileMimeType
        this.localMimeType = this.fileMimeType
      } else {
        this.localFileSrc = source
        this.localPreviousSrc = source
        this.localPreviousMimeType = file?.mimetype
        this.localMimeType = file?.mimetype
      }
    },
    async loadFile () {
      try {
        const result = await this.$store.dispatch('file/downloadFile', { id: this.fileId })
        getBase64FromDownloadedFile(result, (sourceResult) => {
          this.loadSource(result, sourceResult)
        })
      } catch (error) {
        console.log('loadFile', error)
      }
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    calculateIframeHeight () {
      let totalHeight = 0

      // the setTimeout is a must-have to access the forEach elements of the className array
      setTimeout(() => {
        document.querySelectorAll('.p-dialog-header').forEach((element) => {
          totalHeight += element.clientHeight
        });
        document.querySelectorAll('.p-dialog-footer').forEach((element) => {
          totalHeight += element.clientHeight
        });

        // 0.9 for the 90% of the dialog max-height
        const height = (window.innerHeight - (window.innerHeight * 0.9)) + totalHeight
        this.iframeHeight = `calc(100vh - ${height}px)`
      }, 0);
    },
    onUpload (file, emitEvent) {
      this.uploadedFileEvent = emitEvent

      // save as form for uploading in back
      const form = new FormData()
      form.append('filename', file.files[0].name)
      form.append('file', file.files[0])
      this.localFileForm = form
      // save as a base64 string
      getBase64(file.files[0], (result) => {
        this.localMimeType = file.files[0].type
        this.localFileSrc = result
        this.fileUploaded = true
      });
    },
    prepareConfirmDialogForDelete () {
      if (this.validationDialog) {
        this.confirmDialogValidate = () => {
          this.deleteFile()
          this.confirmDialog = false
        }
        this.confirmDialogTitle = 'Supprimer le document'
        this.confirmDialogInfo = 'En validant, vous supprimerez le document.'
        this.confirmDialog = true
      } else {
        this.deleteFile()
      }
    },
    prepareConfirmDialogForValidate () {
      if (this.validationDialog) {
        this.confirmDialogValidate = () => {
          this.validateFile()
          this.confirmDialog = false
        }
        this.confirmDialogTitle = 'Document importé manuellement'
        this.confirmDialogInfo = 'En validant, vous téléversez le document.'
        this.confirmDialog = true
      } else {
        this.validateFile()
      }
    },
    cancelFileUploaded () {
      this.resetDefaultFilePreview()
      this.localMimeType = this.localPreviousMimeType
      this.localFileSrc = this.localPreviousSrc
      this.$emit('cancelFileUpload')
    },
    async validateFile () {
      const payload = {
        form: this.localFileForm
      }
      if (this.defineFileType) {
        payload.fileType = this.fileType
      }

      if (this.autoUploadFile) {
        payload.documentId = await this.documentValidateFile(payload)
      }

      // if a file has been uploaded by the first time or by a replaceFile, it takes it
      // else, it just considers to validate the file
      this.$emit(this.uploadedFileEvent ?? 'validateFile', payload)
      this.dialogActive = false
      await this.resetDefaultFilePreview()
    },
    async documentValidateFile (payload) {
      let documentId = this.documentId
      switch (this.uploadedFileEvent) {
        case 'firstFileUpload':
          if (this.independentId && (payload.fileType || this.documentType)) {
            payload.form.append('documentStatus', this.constants.DOCUMENT_STATUS.DRAFT) // todo: what to do with this DRAFT status, should this be selectable ?
            payload.form.append('documentType', payload.fileType ?? this.documentType)
            payload.form.append('documentMission', null) // todo: what to do with this DRAFT status, should this be selectable ?
            const newDocument = await this.$store.dispatch('documents/createDocument', {form: payload.form, id: this.independentId})
            documentId = newDocument.id
          } else if (this.autoUploadFile) {
            console.error('ERROR CREATE: independentId et le type de document sont manquant')
          }
          break
        case 'replaceFile':
          if (this.documentId) {
            await this.$store.dispatch('documents/updateDocument', {
              form: payload.form,
              id: this.documentId
            })
          } else if (this.autoUploadFile) {
            console.error('ERROR UPDATE: documentId est manquant')
          }
          break
        default:
          break
      }
      return documentId
    },
    async deleteFile () {
      if (this.autoUploadFile && this.documentId) {
        await this.$store.dispatch('documents/deleteDocument', {id: this.documentId})
      } else if (this.autoUploadFile) {
        console.error('ERROR DELETE: documentId est manquant')
      }
      this.$emit('deleteFile')
      this.dialogActive = false
      await this.resetDefaultFilePreview()
    },
    closeConfirmDialog () {
      this.confirmDialog = false
    },
    async resetDefaultFilePreview () {
      this.fileUploaded = false
      this.localFileSrc = null
      this.localFileForm = null
      this.localMimeType = ''
      this.filePreviewReplaceButton = false
    }
  }
}
</script>

<style>
.dialog-file-preview .p-dialog-content{
  overflow-y: hidden;
}
</style>
