<template>
  <Dialog v-model:visible="dialogActive" :draggable="false" :modal="true" :closable="false" class="confirm-dialog" content-class="dialog-content">
    <template #header>
      <div style="width: 100%; text-align: center;">
        <i class="pi pi-check-circle text-green-500 text-xl lg:text-4xl"></i>
        <p v-if="title" class="text-black-alpha-90 text-xl mt-1 lg:text-4xl">{{ title }}</p>
      </div>
    </template>
    <div v-if="textQuestion"
         class="p-3 m-auto text-green-500 mt-1 w-10 text-center text-md
          lg:text-xl lg:mt-3 lg:w-full"
    >
      {{ textQuestion }}
    </div>
    <div v-if="textInfo" class="text-center lg:mt-2 lg:mb-3">
      {{ textInfo }}
    </div>
    <template #footer>
      <div class="mt-2 lg:mt-4">
        <Button v-if="refuseAction" :label="refuseButtonLabel" class="p-button-text" @click="refuseAction"/>
        <Button v-if="validateAction" :label="validateButtonLabel" autofocus @click="validateAction"/>
      </div>
    </template>
  </Dialog>
</template>

<script>
export default {
  name: "DialogConfirm",
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
    title: {
      type: String,
      default: '',
      required: true
    },
    textQuestion: {
      type: String,
      default: '',
      required: true
    },
    textInfo: {
      type: String,
      default: '',
      required: true
    },
    refuseButtonLabel: {
      type: String,
      default: 'Retour',
    },
    validateButtonLabel: {
      type: String,
      default: 'Valider',
    },
    refuseAction: {
      type: Function,
      default: () => {},
      required: true
    },
    validateAction: {
      type: Function,
      default: () => {},
      required: true
    }
  },
  watch: {
    isActive (value) {
      this.dialogActive = value
    }
  },
  data () {
    return {
      dialogActive: false
    }
  }
}
</script>

<style>
.confirm-dialog .dialog-content {
  min-width: 200px;
  /*max-width: 300px;*/
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-top: 1px solid grey !important;
  border-bottom: 1px solid grey !important;
}
.web-dialog .dialog-content {
  min-width: 800px !important;
  max-width: 1280px !important;
}
</style>
